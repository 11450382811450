var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pipelines-overview-page"},[(!_vm.hasRunningWorkers)?_c('CyWorkersWarningMessage',{staticClass:"mb-2"}):_vm._e(),_c('div',{staticClass:"pipelines__header px-4"},[_c('transition',{attrs:{"name":"slide-fade-left","mode":"out-in"}},[(_vm.isSearchActive)?_c('CySearchBox',{ref:"headerSearchbar",staticClass:"search-field",attrs:{"autofocus":"","placeholder":_vm.$t('searchBy'),"append-icon":"search","clearable":""},on:{"click:clear":function($event){return _vm.$toggle.isSearchActive(false)},"blur":function($event){return _vm.$toggle.isSearchActive(false)}},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchTerm"}}):_vm._e()],1),_c('transition',{attrs:{"name":"slide-fade-right"}},[(!_vm.isSearchActive)?_c('div',{staticClass:"d-flex grow"},[_c('v-icon',{staticClass:"align-self-center mr-6",attrs:{"color":"primary"},on:{"click":_vm.$toggle.isSearchActive}},[_vm._v(" search ")]),_c('CyDataTableFilters',{staticClass:"mx-2",attrs:{"filters":_vm.filters},scopedSlots:_vm._u([{key:"item-content",fn:function(ref){
var item = ref.item;
var items = ref.items;
return [(item.key === 'statuses')?[_c('v-list-item-avatar',[_c('v-icon',{class:item.value},[_vm._v(" "+_vm._s(_vm.getStatusIcon(item.value))+" ")])],1),_c('v-list-item-title',{class:[{ 'font-weight-bold': _vm._.includes(items, item) }]},[_vm._v(" "+_vm._s(item.title)+" ")])]:_vm._e()]}}],null,false,2034916067)}),_c('CyButton',{directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:('UpdatePipeline'),expression:"'UpdatePipeline'"}],staticClass:"ml-auto",attrs:{"loading":_vm.fetchInProgress,"variant":"secondary","theme":"secondary","icon":"sync","visible":!_vm.isInitiallyLoading},on:{"click":_vm.getPipelines}},[_vm._v(" "+_vm._s(_vm.$t('forms.btnRefresh'))+" ")])],1):_vm._e()])],1),(!_vm._.$isEmpty(_vm.activeFilters) || !_vm._.$isEmpty(_vm.searchTerm))?_c('CyDataTableTags',{staticClass:"elevation-1",attrs:{"filters":_vm.filters,"search-term":_vm.searchTerm},on:{"clear-search":function($event){_vm.searchTerm = ''}},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
var tag = ref.tag;
var remove = ref.remove;
return [(tag.key !== _vm.$static.searchTermQueryParam)?void 0:_vm._e(),(tag.key === 'statuses[in]')?[_c('v-chip',{staticClass:"status-chip",attrs:{"close":""},on:{"click:close":function($event){return remove(tag.key, tag.id)}}},[_c('v-avatar',[_c('v-icon',{class:tag.id},[_vm._v(" "+_vm._s(_vm.getStatusIcon(tag.id))+" ")])],1),_vm._v(" "+_vm._s(tag.value)+" ")],1)]:_vm._e()]}}],null,false,718740884)}):_vm._e(),_c('v-row',{staticClass:"mt-4"},[(_vm.fetchInProgress)?_c('v-progress-circular',{staticClass:"page-loader",attrs:{"indeterminate":"","color":"secondary","size":"100"}}):[(!_vm._.isEmpty(_vm.activeFilters) && _vm._.isEmpty(_vm.pipelines))?_c('v-col',{staticClass:"text-center mt-6",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t('noFilteredPipelines'))+" ")]):(_vm._.isEmpty(_vm.pipelines))?_c('v-col',{staticClass:"text-center mt-6",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t('noPipelinesAvailable'))+" ")]):_vm._e(),_c('v-col',{staticClass:"pipelines__grid",attrs:{"data-cy":"pipelines-grid"}},_vm._l((_vm.pipelines),function(pipeline,key){return _c('CyViewsPipelinePreview',{directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:(['GetPipeline', pipeline.name]),expression:"['GetPipeline', pipeline.name]"}],key:key,attrs:{"pipeline":pipeline}})}),1)]],2),_c('div',{class:['legend mt-12', { 'legend--expanded': _vm.isLegendExpanded }]},[_c('div',{staticClass:"legend__title d-flex align-center",on:{"click":_vm.toggleLegend}},[_c('v-icon',{staticClass:"rounded-circle mr-2",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.legendIcon)+" ")]),_vm._v(" "+_vm._s(_vm.$t('legend'))+" ")],1),_vm._l((_vm.$static.statuses),function(ref){
var status = ref.status;
var label = ref.label;
var icon = ref.icon;
return _c('div',{key:status,staticClass:"d-flex legend__option option"},[_c('dl',{staticClass:"d-flex align-center"},[_c('dt',{class:[status, 'option__blob']},[(icon)?_c('v-icon',[_vm._v(" "+_vm._s(icon)+" ")]):_vm._e()],1),_c('dd',{staticClass:"primary--text option__label"},[_vm._v(" "+_vm._s(label)+" ")])])])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }