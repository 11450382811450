<template>
  <v-text-field
    :value="$attrs.value"
    v-bind="$attrs"
    @input="setSearchTerm"
    @click:clear="($event) => $emit('click:clear', $event)"
    @blur="($event) => $emit('blur', $event)">
    <template v-for="(_, slot) in $slots">
      <template :slot="slot">
        <slot :name="slot"/>
      </template>
    </template>
    <template
      v-for="(_, slot) in $scopedSlots"
      #[slot]="props">
      <slot
        :name="slot"
        v-bind="props"/>
    </template>
  </v-text-field>
</template>

<script>
export const WAIT_TIME = 400

export default {
  name: 'CySearchBox',
  inheritAttrs: false,
  methods: {
    setSearchTerm: _.debounce(function (value) {
      this.$emit('input', value)
    }, WAIT_TIME),
  },
}
</script>
