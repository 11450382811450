<template>
  <v-chip
    :class="['member-tag', { 'member-tag--medium': medium }]"
    close
    data-cy="member-tag"
    @click:close="$emit('remove')">
    <CyAvatar
      :item="member"
      :class="['member-tag__avatar', { 'member-tag__avatar--medium': medium }]"/>
    {{ displayName(member) }}
  </v-chip>
</template>

<script>
import { displayName } from '@/utils/helpers'

export default {
  name: 'CyMemberTag',
  props: {
    member: {
      type: Object,
      validator: (member) => _.$hasAny(member, ['picture_url', 'given_name', 'family_name', 'username']),
      required: true,
    },
    medium: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    displayName,
  },
}
</script>

<style lang="scss" scoped>
  $member-tag-medium: 26px !important;

  .member-tag {
    padding: 0;
    background-color: cy-get-color("grey", "light-2");
    color: cy-get-color("grey", "dark-3");
    font-family: $font-family-code;

    &--medium {
      height: $member-tag-medium;
    }

    &__avatar {
      margin-right: 4px;
      margin-left: -12px;
      background: cy-get-color("primary");
      color: cy-get-color("white");

      &--medium {
        width: $member-tag-medium;
        min-width: $member-tag-medium;
        height: $member-tag-medium;

        ::v-deep .cy-avatar__initials {
          font-size: 12px;
          font-weight: $font-weight-medium;
        }
      }
    }

    ::v-deep {
      .v-chip__content {
        padding: 0 6px 0 12px !important;
      }

      .v-icon:not(.v-chip__close) {
        margin-left: 2px;
        color: cy-get-color("grey", "dark-3");
      }

      .v-chip__close {
        margin: 0 2px 0 8px !important;
      }
    }
  }
</style>
